import { FormControl, FormErrorMessage, Grid, GridItem, Radio, RadioGroup, Text } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import dictionary from '../../../dictionary'
import Card from '../Card'

interface Props {
  register: any
  errors: any
  control: any
  watch: any
  setValue: any
  bundles: any
  disabled?: boolean
  bundleId?: any
}

const CorporateBundles: FC<Props> = ({ register, errors, control, watch, setValue, bundles, disabled, bundleId }) => {
  const type = watch('type')
  const bundle_type = watch('bundle_type')

  useEffect(() => {
    if (!bundleId) {
      if (type !== 'CHARGED') {
        setTypeOptions([
          {
            label: bundles?.[0]?.name?.ar, // basic bundle
            value: bundles?.[0]?.id, // id = 1
          },
          {
            label: bundles?.[1]?.name?.ar, // saving plus bundle
            value: bundles?.[1]?.id, // id = 2
          },
        ])
        setValue('bundle_type', bundles?.[1]?.id)
      } else {
        setTypeOptions([
          {
            label: bundles?.[0]?.name?.ar, // basic bundle
            value: bundles?.[0]?.id, // id = 1
          },
          {
            label: bundles?.[1]?.name?.ar, // saving plus bundle
            value: bundles?.[1]?.id, // id = 2
          },
          {
            label: bundles?.[2]?.name?.ar, // saving pro bundle
            value: bundles?.[2]?.id, // id = 3
            disabled: type !== 'CHARGED',
          },
        ])
        setValue('bundle_type', bundles?.[2]?.id)
      }
    } else {
      setTypeOptions([
        {
          label: bundles?.[0]?.name?.ar, // basic bundle
          value: bundles?.[0]?.id, // id = 1
        },
        {
          label: bundles?.[1]?.name?.ar, // saving plus bundle
          value: bundles?.[1]?.id, // id = 2
        },
        {
          label: bundles?.[2]?.name?.ar, // saving pro bundle
          value: bundles?.[2]?.id, // id = 3
          disabled: type !== 'CHARGED',
        },
      ])
      setValue('bundle_type', bundleId)
    }
  }, [type])

  useEffect(() => {
    if (bundle_type === bundles?.[0]?.id) {
      setValue('auto_refund', false)
      setValue('fraud_detection_fees', 0)
    } else {
      setValue('auto_refund', true)
    }
  }, [bundle_type])

  const [TypeOptions, setTypeOptions] = useState<any>([])

  return (
    <Card p='5'>
      <Grid templateColumns='repeat(12, 1fr)' gap={4}>
        <GridItem colSpan={[12]}>
          <Text
            style={{
              fontFamily: 'Noto Kufi Arabic',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'right',
            }}
            pb={5}
            pr={2}
            borderBottom='1px solid rgba(226, 232, 240, 1)'
          >
            {dictionary().CORPORATE_BUNDLE}
          </Text>
        </GridItem>
        <GridItem colSpan={[12]}>
          <FormControl id={dictionary().CORPORATE_BUNDLE} w='50%' isInvalid={!!errors?.type} data-test='BUNDLE_TYPE'>
            <Controller
              name={'bundle_type'}
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} isDisabled={disabled} mt={'37px'} mb={'37px'}>
                  <Grid templateColumns='repeat(12, 1fr)' gap={10} color='#344054'>
                    {TypeOptions.map((item: any, index: number) => {
                      return (
                        <GridItem key={index} colSpan={[12, 6, 4]}>
                          <Radio
                            data-test={`BUNDLE_${item.value}`}
                            required
                            disabled={item?.disabled || false}
                            value={item.value}
                            color='purple'
                            alignItems={'center'}
                            style={{
                              fontFamily: 'Noto Kufi Arabic',
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              textAlign: 'left',
                              pointerEvents: item?.disabled ? 'none' : 'unset',
                            }}
                            onChange={() => setValue('bundle_type', item.value)}
                          >
                            <Text mt={0} mb={1} mr={1} whiteSpace='nowrap'>
                              {item.label}
                            </Text>
                          </Radio>
                        </GridItem>
                      )
                    })}
                  </Grid>
                </RadioGroup>
              )}
              rules={{
                required: true && dictionary().REQUIRED,
              }}
            />
            <FormErrorMessage>{errors?.type?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>
        {/* {type && getChargingProfileTypeInputs()} */}
      </Grid>
    </Card>
  )
}

export default CorporateBundles

// export const DurationRadioOptions: FC<InputProps> = ({ register, errors, control, watch }) => {
//   const type = watch('durationType')
//   const DurationOptions = [
//     {
//       label: dictionary().FIFTEEN_DAYS,
//       value: '15',
//     },
//     {
//       label: dictionary().THIRTY_DAYS,
//       value: '30',
//     },
//   ]
//   const options = [
//     {
//       type: 'radio',
//       register: register?.('durationType', {
//         required: dictionary().REQUIRED,
//       }),
//       options: [
//         {
//           label: dictionary().PERIOD,
//           value: 'FIXED_DURATION',
//         },
//         {
//           label: dictionary().SELECT_PERIOD,
//           value: 'CUSTOM_DURATION',
//         },
//       ],
//     },
//   ]

//   const getInputsType = () => {
//     const selectDuration: Detail = {
//       type: 'select',
//       register: register?.('trial_duration_in_days', {
//         required: dictionary().REQUIRED,
//       }),
//       options: DurationOptions,
//       name: '',
//       value: '',
//     }

//     const customDuration = {
//       type: 'number',
//       register: register?.('trial_duration_in_days', {
//         required: dictionary().REQUIRED,
//         min: {
//           value: 0,
//           message: dictionary().NO_NEGATIVE_NUMBER,
//         },
//       }),
//       name: '',
//       value: '',
//     }

//     switch (type) {
//       case 'FIXED_DURATION':
//         return (
//           <Box w={'20%'} mb={2}>
//             <Text
//               style={{
//                 fontFamily: 'Noto Kufi Arabic',
//                 fontSize: '14px',
//                 fontWeight: 500,
//                 lineHeight: '20px',
//                 textAlign: 'left',
//               }}
//               color='#808080'
//               mb={2}
//               pt={'20px'}
//             >
//               <FormEdit detail={selectDuration} control={control} errors={errors} />
//             </Text>
//           </Box>
//         )
//       case 'CUSTOM_DURATION':
//         return (
//           <Box w={'20%'} mb={2}>
//             <Text
//               style={{
//                 fontFamily: 'Noto Kufi Arabic',
//                 fontSize: '14px',
//                 fontWeight: 500,
//                 lineHeight: '20px',
//                 textAlign: 'left',
//               }}
//               color='#808080'
//               mb={2}
//               pt={'20px'}
//             >
//               <FormEdit detail={customDuration} control={control} errors={errors} />
//             </Text>
//           </Box>
//         )
//       default:
//         return null
//     }
//   }

//   return (
//     <>
//       {options.map((item: any, index: number) => (
//         <GridItem colSpan={[8, 4, 4]} key={index}>
//           <Text
//             color='#808080'
//             style={{
//               fontFamily: 'Noto Kufi Arabic',
//               fontSize: '14px',
//               fontWeight: 500,
//               lineHeight: '20px',
//               textAlign: 'left',
//             }}
//             pt={'20px'}
//           >
//             <FormEdit key={index} detail={item} control={control} errors={errors} />
//           </Text>
//         </GridItem>
//       ))}
//       <GridItem colSpan={[12]}>{getInputsType()}</GridItem>
//     </>
//   )
// }

// const ChargingTypeInputs: FC<InputProps> = ({ register, errors, control }) => {
//   const options = [
//     {
//       name: dictionary().END_DATE,
//       type: 'date',
//       register: register?.('expires_at', {
//         required: dictionary().REQUIRED,
//       }),
//     },
//   ]

//   return (
//     <>
//       {options.map((item: any, index: number) => (
//         <GridItem colSpan={[8, 4, 4]} key={index} mb={2}>
//           <Text fontSize='19px' fontWeight='600'>
//             <Text color='#808080' mb={2} fontSize='16px'>
//               {item.name} <Reqiured />
//             </Text>
//             <FormEdit key={index} detail={item} control={control} errors={errors} />
//           </Text>
//         </GridItem>
//       ))}
//     </>
//   )
// }
