import { Box, Container, Flex, Grid, GridItem } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { pathOr } from 'ramda'
import Header from '../../components/core/Header'
import Pagination from '../../components/core/Pagination'
import Table from '../../components/core/Table'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import { SummaryItem } from '../stats/Summary'
import formatAsCurrency from '../../helpers/formatAsCurrency'
import { useFilterStore } from '../../stores/filterStore'

const headers = [
  {
    id: 'date',
    key: 'created_at',
    name: dictionary().TRANSACTION_DATE,
    type: 'date',
  },
  {
    id: 'time',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'id',
    key: 'transaction_id',
    name: dictionary().TRANSACTION_ID,
    type: 'text',
  },
  {
    id: 'service_type',
    key: 'service_type',
    name: dictionary().ACCOUNT_TYPE,
    type: 'text',
  },
  {
    id: 'actor_name',
    key: 'actor_name',
    name: dictionary().STAFF_NAME,
    type: 'text',
  },
  {
    id: 'actor_type',
    key: 'actor_type',
    name: dictionary().STAFF_ROLE,
    type: 'text',
  },
  {
    id: 'action',
    key: 'action',
    name: dictionary().TRASEFER_DIRECRTION,
    type: 'text',
  },
  {
    id: 'amount',
    key: 'amount',
    name: dictionary().AMOUNT,
    type: 'cash',
  },
  {
    id: 'remaining_quota_before',
    key: 'remaining_quota_before',
    name: dictionary().BALANCE_BEFORE_TRANSACTION,
    type: 'cash',
  },
  {
    id: 'remaining_quota_after',
    key: 'remaining_quota_after',
    name: dictionary().BALANCE_AFTER_TRANSACTION,
    type: 'cash',
  },
]

const VehicleBalanceDetails: React.FC = () => {
  const { page } = useFilterStore()
  const { id } = useParams<{ id: string }>()
  const [balance, setBalance] = useState(0)

  const { mutate: getVehicleData } = useMutation(
    () => {
      return fetch('GET', `/vehicles/${id}`)
    },
    {
      onSuccess: (data) => {
        setBalance(data.vehicle.vehicle_quota?.remaining_quota)
      },
    },
  )
  const { isLoading, mutate, data } = useMutation(() => {
    return fetch('GET', `/vehicle-ledger/vehicle-quotas-activities?vehicle_id=${id}&page=${page}&page_size=30`)
  })

  useEffect(() => {
    getVehicleData()
  }, [])

  useEffect(() => {
    mutate()
  }, [page])

  return (
    <Container maxW='100%' width='100%' h='100vh' padding={5}>
      <Flex flexDir='column' sx={{ '& > *:not(:last-child)': { marginBottom: '15px' } }}>
        <Header title={'تفاصيل رصيد المركبة'} to={''} />
        <Grid templateColumns='repeat(12, 1fr)' alignItems={'center'}>
          <GridItem colSpan={[12, 2, 2, 1]}>
            <Box m={['10px']} mb={0}>
              <SummaryItem
                sty={{ backgroundColor: 'white', color: 'black', textAlign: 'center' }}
                label={dictionary().AVIALABLE_BALANCE}
                value={`${formatAsCurrency(balance ? balance : 0)}`}
              />
            </Box>
          </GridItem>
          <GridItem colSpan={[12]} display='flex' alignItems='center' justifyContent='flex-end'>
            <Pagination totalCount={data?.total || 0} pageSize={30} />
          </GridItem>
          <GridItem colSpan={[12]}>
            {isLoading ? (
              <b
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {dictionary().SEARCH_LOADING}
              </b>
            ) : data?.data?.length === 0 ? (
              <b
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {dictionary().NO_RESULTS_FOUND}
              </b>
            ) : (
              <Table raised enableIdRedirect={false} headers={headers} data={pathOr([], ['data'], data)} />
            )}
          </GridItem>
        </Grid>
      </Flex>
    </Container>
  )
}

export default VehicleBalanceDetails
