import { Grid, GridItem, Text } from '@chakra-ui/react'
import React, { FC, useEffect } from 'react'
import dictionary from '../../../dictionary'
import Card from '../Card'
import Reqiured from '../reqiured'
import FormEdit from './FormEdit'

interface Props {
  register: any
  errors: any
  control: any
  watch: any
  setValue: any
  getValues: any
  // ----------- disable all fields ------------
  disabled?: boolean
  // -------------------------------------------
  // -------- disable specific fields ----------
  disableFields?: {
    enable_services?: boolean
    enable_car_wash?: boolean
    enable_oil_change?: boolean
    car_wash_fees?: boolean
    oil_change_fees?: boolean
  }
  // -------------------------------------------
}

const testStyle = {
  fontFamily: 'Noto Kufi Arabic',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  textAlign: 'right',
}

const CarServicesFee: FC<Props> = ({ register, errors, control, watch, setValue, getValues, disabled, disableFields = {} }) => {
  const enableServices = watch('enable_services')
  const enableCarWash = watch('enable_car_wash')
  const enableOilChange = watch('enable_oil_change')
  useEffect(() => {
    if (!enableServices) {
      setValue('enable_car_wash', '')
      setValue('enable_oil_change', '')
    }
  }, [enableServices])
  useEffect(() => {
    if (!enableCarWash) {
      setValue('car_wash_fees', 0)
    }
  }, [enableCarWash])
  useEffect(() => {
    if (!enableOilChange) {
      setValue('oil_change_fees', 0)
    }
  }, [enableOilChange])

  return (
    <Card p={5}>
      <Grid templateColumns='repeat(12, 1fr)' gap={4} pb={4}>
        <GridItem colSpan={[12]}>
          <Text
            style={{
              fontFamily: 'Noto Kufi Arabic',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'right',
            }}
            mb={5}
            pb={5}
            pr={2}
            borderBottom='1px solid rgba(226, 232, 240, 1)'
          >
            {dictionary().SERVICES}
          </Text>
        </GridItem>
        <GridItem colSpan={[8, 4, 3]} mb={2}>
          <Text style={testStyle as any} mb={2}>
            <Text style={testStyle as any} mb={2}>
              {dictionary().SERVICES}
            </Text>
            <FormEdit
              detail={{
                name: dictionary().SERVICES,
                type: 'checkbox',
                register: register?.('enable_services', { required: false }),
                label: dictionary().CHOOSE_SERVICES,
                value: '',
              }}
              control={control}
              errors={errors}
              disabled={disabled || disableFields?.enable_services}
              getValues={getValues}
            />
          </Text>
        </GridItem>
        {enableServices && (
          <>
            <GridItem colSpan={[12]}>
              <Text style={testStyle as any}>{dictionary().CHOOSE_SERVICES}</Text>
            </GridItem>
            <GridItem colSpan={[8, 4, 3]} mb={2}>
              <Text style={testStyle as any} mb={2}>
                <FormEdit
                  detail={{
                    name: dictionary().CAR_WASH,
                    type: 'checkbox',
                    register: register?.('enable_car_wash', { required: false }),
                    label: dictionary().CAR_WASH,
                    value: '',
                  }}
                  control={control}
                  errors={errors}
                  disabled={disabled || disableFields?.enable_car_wash}
                  getValues={getValues}
                />
              </Text>
            </GridItem>
            <GridItem colSpan={[8, 4, 3]} mb={2}>
              <Text style={testStyle as any} mb={2}>
                <FormEdit
                  detail={{
                    name: dictionary().OIL_CHANGE,
                    type: 'checkbox',
                    register: register?.('enable_oil_change', { required: false }),
                    label: dictionary().OIL_CHANGE,
                    value: '',
                  }}
                  control={control}
                  errors={errors}
                  disabled={disabled || disableFields?.enable_oil_change}
                  getValues={getValues}
                />
              </Text>
            </GridItem>
          </>
        )}
        <GridItem colSpan={[8, 4]} />
        {enableCarWash && (
          <>
            <GridItem colSpan={[8, 4, 3]} mb={2}>
              <Text style={testStyle as any} mb={2}>
                <Text style={testStyle as any} mb={2}>
                  {dictionary().CAR_WASH_FEE} (%) <Reqiured />
                </Text>
                <FormEdit
                  detail={{
                    name: dictionary().CAR_WASH_FEE,
                    type: 'text',
                    register: register?.('car_wash_fees', {
                      required: dictionary().REQUIRED,
                      pattern: {
                        value: /^\d+(\.\d{0,2})?$/,
                        message: dictionary().ENTER_VALID_AMOUNT_UP_TO_2_DECIMAL,
                      },
                      min: {
                        value: 0,
                        message: dictionary().NO_NEGATIVE_NUMBER,
                      },
                      max: {
                        value: 100,
                        message: dictionary().CANNOT_BE_MORE_THAN_100,
                      },
                    }),
                    label: dictionary().CAR_WASH_FEE,
                    value: '',
                  }}
                  control={control}
                  errors={errors}
                  disabled={disabled || disableFields?.car_wash_fees}
                  getValues={getValues}
                />
              </Text>
            </GridItem>
          </>
        )}
        {enableOilChange && (
          <>
            <GridItem colSpan={[8, 4, 3]} mb={2}>
              <Text style={testStyle as any} mb={2}>
                <Text style={testStyle as any} mb={2}>
                  {dictionary().OIL_CHANGE_FEE} (%) <Reqiured />
                </Text>
                <FormEdit
                  detail={{
                    name: dictionary().OIL_CHANGE_FEE,
                    type: 'text',
                    register: register?.('oil_change_fees', {
                      required: dictionary().REQUIRED,
                      pattern: {
                        value: /^\d+(\.\d{0,2})?$/,
                        message: dictionary().ENTER_VALID_AMOUNT_UP_TO_2_DECIMAL,
                      },
                      min: {
                        value: 0,
                        message: dictionary().NO_NEGATIVE_NUMBER,
                      },
                      max: {
                        value: 100,
                        message: dictionary().CANNOT_BE_MORE_THAN_100,
                      },
                    }),
                    label: dictionary().OIL_CHANGE_FEE,
                    value: '',
                  }}
                  control={control}
                  errors={errors}
                  disabled={disabled || disableFields?.oil_change_fees}
                  getValues={getValues}
                />
              </Text>
            </GridItem>
          </>
        )}
      </Grid>
    </Card>
  )
}

export default CarServicesFee
