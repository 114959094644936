import { Box, FormControl, FormErrorMessage, Grid, GridItem, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import dictionary from '../../../dictionary'
import Card from '../Card'
import Reqiured from '../reqiured'
import FormEdit from './FormEdit'
import { Detail } from './VersionDetailsData'

interface Props {
  register: any
  errors: any
  control: any
  watch: any
  setValue?: any
  getValues?: any
}

interface InputProps {
  register: any
  errors: any
  control: any
  watch: any
  setValue?: any
  getValues?: any
}

const TypeOptions = [
  {
    label: dictionary().CHARGED,
    value: 'CHARGED',
  },
  {
    label: dictionary().FREE_TRIAL,
    value: 'FREE_TRIAL',
  },
  {
    label: dictionary().PAID_TRIAL,
    value: 'PAID_TRIAL',
  },
]

const AddProfileType: FC<Props> = ({ register, errors, control, watch, setValue, getValues }) => {
  const type = watch('type')

  const getChargingProfileTypeInputs = () => {
    switch (type) {
      case 'CHARGED':
        return <ChargingTypeInputs register={register} errors={errors} control={control} watch={watch} />
      case 'PAID_TRIAL':
      case 'FREE_TRIAL':
        return <DurationRadioOptions register={register} errors={errors} control={control} watch={watch} setValue={setValue} getValues={getValues} />
      default:
        return null
    }
  }

  useEffect(() => {
    getChargingProfileTypeInputs()
  }, [type])

  return (
    <Card p='5'>
      <Grid templateColumns='repeat(12, 1fr)' gap={4}>
        <GridItem colSpan={[12]}>
          <Text
            style={{
              fontFamily: 'Noto Kufi Arabic',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'right',
            }}
            mb={5}
            pb={5}
            pr={2}
            borderBottom='1px solid rgba(226, 232, 240, 1)'
          >
            {dictionary().TYPE_OF_CHARGING_PROFILE}
          </Text>
        </GridItem>
        <GridItem colSpan={[12]}>
          <FormControl id={dictionary().CHARGING_PROFILE_TYPE} w='50%' isInvalid={!!errors?.type}>
            <Controller
              name={'type'}
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction='row' gap={8} color='#808080'>
                    {TypeOptions.map((item: any, index: number) => (
                      <Radio
                        key={index}
                        value={item.value}
                        color='purple'
                        alignItems={'center'}
                        data-test={`TYPE_${item.value}`}
                        style={{
                          fontFamily: 'Noto Kufi Arabic',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          textAlign: 'left',
                        }}
                      >
                        {item.label}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
              rules={{
                required: true && dictionary().REQUIRED,
              }}
            />
            <FormErrorMessage>{errors?.type?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>
        {type && getChargingProfileTypeInputs()}
      </Grid>
    </Card>
  )
}

export default AddProfileType

export const DurationRadioOptions: FC<InputProps> = ({ register, errors, control, watch, setValue, getValues }) => {
  const type = watch('durationType')

  useEffect(() => {
    if (!type) {
      setValue('durationType', 'FIXED_DURATION')
    }
  }, [])

  const DurationOptions = [
    {
      label: dictionary().FIFTEEN_DAYS,
      value: '15',
    },
    {
      label: dictionary().THIRTY_DAYS,
      value: '30',
    },
  ]
  const options = [
    {
      type: 'radio',
      register: register?.('durationType', {
        required: dictionary().REQUIRED,
      }),
      options: [
        {
          label: dictionary().PERIOD,
          value: 'FIXED_DURATION',
        },
        {
          label: dictionary().SELECT_PERIOD,
          value: 'CUSTOM_DURATION',
        },
      ],
    },
  ]

  const getInputsType = () => {
    const selectDuration: Detail = {
      type: 'select',
      register: register?.('trial_duration_in_days', {
        required: dictionary().REQUIRED,
      }),
      options: DurationOptions,
      name: '',
      value: '',
    }

    const customDuration = {
      type: 'number',
      register: register?.('trial_duration_in_days', {
        required: dictionary().REQUIRED,
        min: {
          value: 0,
          message: dictionary().NO_NEGATIVE_NUMBER,
        },
      }),
      name: '',
      value: '',
    }

    switch (type) {
      case 'FIXED_DURATION':
        return (
          <Box w={'20%'} mb={2}>
            <Text
              style={{
                fontFamily: 'Noto Kufi Arabic',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
              }}
              color='#808080'
              mb={2}
              pt={'20px'}
            >
              <FormEdit detail={selectDuration} control={control} errors={errors} />
            </Text>
          </Box>
        )
      case 'CUSTOM_DURATION':
        return (
          <Box w={'20%'} mb={2}>
            <Text
              style={{
                fontFamily: 'Noto Kufi Arabic',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
              }}
              color='#808080'
              mb={2}
              pt={'20px'}
            >
              <FormEdit detail={customDuration} control={control} errors={errors} />
            </Text>
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <>
      {options.map((item: any, index: number) => (
        <GridItem colSpan={[8, 4, 4]} key={index}>
          <Text
            color='#808080'
            style={{
              fontFamily: 'Noto Kufi Arabic',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              textAlign: 'left',
            }}
            pt={'20px'}
          >
            <FormEdit key={index} detail={item} control={control} errors={errors} setValue={setValue} getValues={getValues} disabled={false} />
          </Text>
        </GridItem>
      ))}
      <GridItem colSpan={[12]}>{getInputsType()}</GridItem>
    </>
  )
}

const ChargingTypeInputs: FC<InputProps> = ({ register, errors, control }) => {
  const options = [
    {
      name: dictionary().END_DATE,
      type: 'date',
      register: register?.('expires_at', {
        required: dictionary().REQUIRED,
      }),
    },
  ]

  return (
    <>
      {options.map((item: any, index: number) => (
        <GridItem colSpan={[8, 4, 4]} key={index} mb={2}>
          <Text fontSize='19px' fontWeight='600'>
            <Text color='#808080' mb={2} fontSize='16px'>
              {item.name} <Reqiured />
            </Text>
            <FormEdit key={index} detail={item} control={control} errors={errors} />
          </Text>
        </GridItem>
      ))}
    </>
  )
}
