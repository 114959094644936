import { Button, Flex, Popover, PopoverTrigger, PopoverContent, PopoverBody, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { Corporate } from '../../pages/charging-profiles/ChargingProfileType'

type Props = {
  corporates: Corporate[]
  maxDispkay?: number
  listOf?: keyof Corporate
}

const CustomerCorporateList: FC<Props> = ({ corporates = [], maxDispkay = 3, listOf = 'name' }) => {
  //------------------ slice and add +...# to corporates if more than maxDispkay ------------------
  const newCorporates = () => {
    if (corporates?.length > maxDispkay) {
      const displayedCorporates = corporates.slice(0, maxDispkay - 1).map((corp) => corp[listOf])
      return displayedCorporates.concat(`${corporates.length - maxDispkay + 1}+...`)
    }
    return corporates.map((corp) => corp[listOf])
  }
  //-----------------------------------------------------------------------------------------------

  return (
    <Popover placement='bottom-end' closeOnBlur>
      <PopoverTrigger>
        <Button
          backgroundColor={'transparent'}
          _hover={{ backgroundColor: 'transparent' }}
          _active={{ backgroundColor: 'transparent' }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-start'}
          mt={2}
          p={0}
          width={'fit-content'}
          cursor={'context-menu'}
        >
          {newCorporates()?.map((item: string) => (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              h={'28px'}
              maxW={'94px'}
              pr={15}
              pl={15}
              pt={2}
              pb={2}
              borderRadius={'10px'}
              backgroundColor={'#6F65DB1A'}
              borderWidth={'1px'}
              borderColor={'#6F65DB26'}
              ml={2}
              _hover={{ backgroundColor: '#e5e5ff' }}
              _active={{ backgroundColor: '#d6d6ff' }}
              cursor={'pointer'}
              key={item}
            >
              <Text fontSize={'12px'} fontWeight={'600'} color={'#6E54B5'} letterSpacing={'0.5px'} mt={0} mb={0} flexWrap={'wrap'} whiteSpace={'nowrap'}>
                {item.length > 8 ? item?.substr(0, 8) + '..' : item}
              </Text>
            </Flex>
          ))}
        </Button>
      </PopoverTrigger>

      <PopoverContent width='fit-content' minW={'230px'} boxShadow='lg' borderWidth='1px'>
        <PopoverBody>
          <Flex flexDirection={'column'}>
            {corporates?.map((item) => (
              <Text fontSize={'14px'} fontWeight={'400'} color={'#000000'} letterSpacing={'0.5px'} mt={2} mb={2} key={item[listOf]}>
                {item[listOf]}
              </Text>
            ))}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default CustomerCorporateList
