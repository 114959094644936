import { CHARGING_PROFILE_CUSTOM_RENDERS } from '../../constants/charging_profile/chargingProfileCustomRenders'
import { CHARGING_PROFILE_EXCEL_HEADERS } from '../../constants/charging_profile/chargingProfleExcelHeaders'
import fetch from '../../helpers/fetch'
import { useExport } from '../../helpers/useExport'

export const useDownloadChargingProfileExcel = () => {
  const { exportToExcel } = useExport()
  const getExcelFile = async (customerId: any) => {
    try {
      const data = await fetch('GET', `/charging-profile/customer/${customerId}/details`)
      exportToExcel(
        data?.data,
        CHARGING_PROFILE_EXCEL_HEADERS,
        CHARGING_PROFILE_CUSTOM_RENDERS,
        `Customer ${data?.data?.[0]?.customer?.name} Charging Profiles`,
        `Customer Data`,
      )
    } catch (error) {
      console.log('🚀 ~ downloadChargingProfileExcel ~ error:', error)
    }
  }

  return { getExcelFile }
}
