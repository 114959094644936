import { Grid, GridItem, Text } from '@chakra-ui/react'
import { FC, Fragment, useEffect, useState } from 'react'
import dictionary from '../../../dictionary'
import Card from '../Card'
import Reqiured from '../reqiured'
import FormEdit from './FormEdit'
import { handleVersionData, VersionData } from './VersionDetailsData'

type Props = {
  enableEdit: boolean
  versionData?: any
  errors: any
  setValue?: any
  control: any
  register: any
  watch?: any
  isAfterTrail?: boolean
  disabled?: boolean
  corporate?: any
  getValues?: any
}

const EditeFeesData: FC<Props> = ({
  versionData,
  errors,
  control,
  register,
  getValues,
  isAfterTrail = false,
  disabled = false,
  watch,
  enableEdit,
  corporate,
  setValue,
}) => {
  const [versionDetails, setVersionDetails] = useState<VersionData[]>([])
  const fraudKey = isAfterTrail ? 'after_trial_profile-fraud_fees_type' : 'fraud_fees_type'
  const [fraudUnit, setFraudUnit] = useState('')

  useEffect(() => {
    const data = handleVersionData(versionData || [], register, isAfterTrail, corporate, getValues)
    setVersionDetails(data)
  }, [])
  useEffect(() => {
    switch (getValues()[fraudKey]) {
      case 'PERCENTAGE':
        setFraudUnit('%')
        break
      case 'PIASTERS_PER_LITER':
        setFraudUnit('قروش/لتر')
        break
      case 'FIXED_AMOUNT':
        setFraudUnit('ج.م')
        break
      default:
        setFraudUnit('')
        break
    }
  }, [watch([fraudKey])])

  return (
    <>
      {versionDetails.map((data, index) => (
        <Card p={5} key={index}>
          <Grid templateColumns='repeat(12, 1fr)' gap={4} pb={4}>
            <GridItem colSpan={[12]}>
              <Text
                style={{
                  fontFamily: 'Noto Kufi Arabic',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  textAlign: 'right',
                }}
                mb={5}
                pb={5}
                pr={2}
                borderBottom='1px solid rgba(226, 232, 240, 1)'
              >
                {data.label}
              </Text>
            </GridItem>
            <Fragment>
              {data.details.map((detail, index) => (
                <GridItem colSpan={[8, 4, 3]} key={index} mb={2}>
                  <Text
                    style={{
                      fontFamily: 'Noto Kufi Arabic',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '20px',
                      textAlign: 'right',
                    }}
                    mb={2}
                  >
                    <Text
                      data-test={detail.name}
                      style={{
                        fontFamily: 'Noto Kufi Arabic',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        textAlign: 'right',
                      }}
                      mb={2}
                    >
                      {detail.name} {detail.name === dictionary().FRUAD_FEES && `(${fraudUnit})`} <Reqiured />
                    </Text>
                    <FormEdit detail={detail} control={control} errors={errors} disabled={disabled} getValues={getValues} setValue={setValue} />
                  </Text>
                </GridItem>
              ))}
            </Fragment>
          </Grid>
        </Card>
      ))}
    </>
  )
}

export default EditeFeesData
