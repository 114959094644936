import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Required from '../../components/core/reqiured'
import SaveButton from '../../components/core/SaveButton'
import { INDUSTRY_TYPES } from '../../constants/INDUSTRY_TYPES'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import { useStore } from '../../store'
import DepositConfigurationModel from './DepositConfigurationModel'

type EditCorporateVariables = {
  pin_code_digits_number: number
  id: number
  name: string
  credit_limit: number
  is_active: boolean
  use_shifts: boolean
  shift_starting: string
  is_advanced: boolean
  consumption_measurement?: string
  replenish_type: any
  all_vehicles_access: boolean
  is_odometer_camera_enabled: boolean
  daily_replenish_time: any
  corporateGroupId: any
  tax_id: string
}

const calcDailyReplenishTime = (time: string) => {
  let hours: any = time?.split(':')[0]
  if (+hours === 0) {
    hours = 10
  } else if (+hours === 1) {
    hours = 11
  } else {
    hours = hours - 2
  }
  hours = (hours as number).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  return `${hours}:${time?.split(':')[1]}`
}

const EditCorporate: React.FC = () => {
  const { userType, role } = useStore((state) => state)
  const { id } = useParams<{ id: string }>()
  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    control,
  } = useForm({ mode: 'onChange' })

  const { isOpen, onOpen, onClose } = useDisclosure()
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess } = useAlert()
  const watchSettlment = watch('settlement')
  const useShiftValue = useRef({})
  useShiftValue.current = watch('use_shifts', '')
  const replenishType = watch('replenish_type')
  const deposit_confirmation_type = watch('deposit_confirmation_type')
  const corporateGroupId = watch('corporateGroupId')
  const [corporateGroup, setCorporateGroup] = useState<any>({})
  const [corporateData, setCorporateData] = useState<any>({})
  const [isInitailRender, setIsInitailRender] = useState(false)
  const [isCorporateGroupInitailValue, setIsCorporateGroupInitailValue] = useState(false)

  const { mutate: getCorporateData } = useMutation(() => fetch('GET', `/corporates/${id}`), {
    onSuccess: (data: any): void => {
      setCorporateData(data)
    },
  })

  const { isLoading, mutate } = useMutation((vars: any) => fetch('PUT', `/corporates`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`/corporates/${id}`)
    },
  })

  const { mutate: getCorporateGroupData } = useMutation(() => fetch('GET', `/corporate-group/${corporateGroupId[0]}`), {
    onSuccess: (data: any): void => {
      setCorporateGroup(data)
    },
  })

  useEffect(() => {
    getCorporateData()
  }, [])

  useEffect(() => {
    if (corporateGroupId && corporateGroupId?.length > 0) getCorporateGroupData()
    else setCorporateGroup({})
  }, [corporateGroupId])

  useEffect(() => {
    if (corporateData) {
      setValue('id', corporateData?.id)
      setValue('name', corporateData?.name)
      setValue('credit_limit', corporateData?.credit_limit)
      setValue('settlement', corporateData?.settlement)
      setValue('tax_id', corporateData?.tax_id)
      setValue('industry', corporateData?.industry)
      setValue('is_active', corporateData?.is_active)
      setValue('use_shifts', corporateData?.use_shifts)
      setValue('shift_starting', corporateData?.shift_starting)
      // setValue('is_advanced', corporateData?.is_advanced)
      setValue('all_vehicles_access', corporateData?.all_vehicles_access)
      // setValue('allow_negative_balance', corporateData?.allow_negative_balance)
      // setValue('is_odometer_camera_enabled', corporateData?.is_odometer_camera_enabled)
      setValue('pin_code_digits_number', `${corporateData?.config?.pin_code_digits_number}`)
      setValue('daily_replenish_time', corporateData?.daily_replenish_time)
      setValue('replenish_type', corporateData?.replenish_type)
      setValue('consumption_measurement', corporateData?.consumption_measurement)
      setValue('deposit_confirmation_type', corporateData?.deposit_confirmation_type)
      setValue('corporateGroupId', typeof corporateData?.corporateGroupId !== 'number' ? [] : [corporateData?.corporateGroupId])
      setTimeout(() => {
        setIsCorporateGroupInitailValue(true)
      }, 2000)
    }
  }, [corporateData])

  useEffect(() => {
    deposit_confirmation_type !== undefined &&
      !isInitailRender &&
      corporateGroupId.length > 0 &&
      isCorporateGroupInitailValue &&
      deposit_confirmation_type !== corporateGroup?.deposit_confirmation_type &&
      onOpen()
    deposit_confirmation_type && setIsInitailRender(false)
  }, [deposit_confirmation_type])

  const onCancelConfig = async () => {
    deposit_confirmation_type === 'FINAL_CONFIRMATION'
      ? await setValue('deposit_confirmation_type', 'INITIAL_CONFIRMATION')
      : await setValue('deposit_confirmation_type', 'FINAL_CONFIRMATION')
    onClose()
  }

  const editCorporate = async (formData: EditCorporateVariables) => {
    !useShiftValue.current && setValue('shift_starting', '00:00')
    formData.name = getValues()?.name.trim()
    formData.replenish_type === '' && (formData.replenish_type = null)
    replenishType !== 'DAILY'
      ? delete formData.daily_replenish_time
      : (formData.daily_replenish_time = getValues()?.daily_replenish_time ? calcDailyReplenishTime(getValues()?.daily_replenish_time) : null)
    formData.corporateGroupId = getValues()?.corporateGroupId?.length === 0 ? null : getValues()?.corporateGroupId[0]
    formData.pin_code_digits_number = +formData.pin_code_digits_number

    mutate([formData])
  }
  useEffect(() => {
    watchSettlment !== 'POST_PAID' && setValue('credit_limit', '0')
  }, [getValues().settlement])

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().EDIT_CORPORATE}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && editCorporate(getValues() as EditCorporateVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(editCorporate as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl id={dictionary().CORPORATE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.name}>
              <FormLabel>
                <>
                  {dictionary().CORPORATE}
                  <Required />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().CORPORATE}
                  type='text'
                  {...register('name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().CORPORATES_GROUP}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.corporateGroupId}
            >
              <FormLabel>{dictionary().CORPORATES_GROUP}</FormLabel>
              <Controller
                data-test={dictionary().CORPORATES_GROUP}
                name={`corporateGroupId`}
                rules={{ required: false }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.corporateGroupId}
                    onChange={onChange}
                    value={value}
                    searchRoute='/corporate-group'
                    singleRoute='/corporate-group'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name}
                  />
                )}
              />
              <FormErrorMessage>{errors?.corporateGroupId?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id='TAX_ID' w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.tax_id}>
              <FormLabel>
                <> {dictionary().TAX_ID}</>
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().TAX_ID}
                  type='text'
                  {...register('tax_id', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.tax_id?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().USERNAME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.credit_limit}
            >
              <FormLabel>
                <>
                  {dictionary().CREDIT_LIMIT}
                  <Required />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().CREDIT_LIMIT}
                  disabled={watchSettlment !== 'POST_PAID' && true}
                  type='number'
                  {...register('credit_limit', {
                    required: dictionary().REQUIRED,
                    valueAsNumber: true,
                  })}
                  defaultValue={'0'}
                />
                <InputLeftElement>{dictionary().PRICING_CURRENCY}</InputLeftElement>
              </InputGroup>

              <FormErrorMessage>{errors.credit_limit?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().INDUSTRY}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.industry}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().INDUSTRY}
                <Required />
              </FormLabel>

              <Select
                data-test={dictionary().INDUSTRY}
                dir='ltr'
                textAlign='right'
                {...register('industry', {
                  required: dictionary().REQUIRED,
                })}
                placeholder={dictionary().INDUSTRY}
              >
                {INDUSTRY_TYPES?.map((item: string, index: number) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors?.industry?.message} </FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().REPLENISH_TIME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.replenish_type}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().REPLENISH_TYPE}
                <Text fontSize='sm' color='gray.500' display={'inline-block'} mr={2}>
                  {replenishType === 'WEEKLY' && `(${dictionary().WEEKLY_REPLENISH_TIME})`}
                  {replenishType === 'MONTHLY' && `(${dictionary().MONTHLY_REPLENISH_TIME})`}
                </Text>
              </FormLabel>

              <Select
                data-test={dictionary().REPLENISH_TIME}
                dir='ltr'
                textAlign='right'
                {...register('replenish_type', {
                  required: false,
                })}
                placeholder={dictionary().REPLENISH_TYPE}
              >
                <option key={3} value='DAILY'>
                  {' '}
                  {dictionary().DAILY}
                </option>
                <option key={1} value='WEEKLY'>
                  {' '}
                  {dictionary().WEEKLY}
                </option>
                <option key={2} value='MONTHLY'>
                  {' '}
                  {dictionary().MONTHLY}
                </option>
              </Select>
              <FormErrorMessage>{errors?.replenish_type?.message} </FormErrorMessage>
            </FormControl>
            {replenishType === 'DAILY' && (
              <FormControl
                id='daily_replenish_time'
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors.daily_replenish_time}
              >
                <FormLabel>{dictionary().DAILY_REPLENISH_TIME}</FormLabel>
                <InputGroup>
                  <Input
                    data-test={dictionary().DAILY_REPLENISH_TIME}
                    type='time'
                    {...register('daily_replenish_time', {
                      required: false,
                      pattern: {
                        value: /^(2[0-3]|[01]?[0-9]):([0-0]?[0-0])$/,
                        message: dictionary().NO_MINUTES,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.daily_replenish_time?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl id={dictionary().STATUS} w='40%' minH='100px' display='inline-block'>
              <FormLabel>{dictionary().STATUS}</FormLabel>
              <Controller
                control={control}
                name='is_active'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} data-test={dictionary().IS_ACTIVE} textTransform='capitalize' value='true' isChecked={getValues().is_active}>
                    {dictionary().IS_ACTIVE}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().ALL_VEHICLES_ACCESS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().DRIVERS}</FormLabel>
              <Controller
                control={control}
                name='all_vehicles_access'
                render={({ field: { onChange } }) => (
                  <Checkbox
                    onChange={onChange}
                    data-test={dictionary().ALL_VEHICLES_ACCESS}
                    defaultValue='false'
                    textTransform='capitalize'
                    value='true'
                    isChecked={getValues().all_vehicles_access}
                  >
                    {dictionary().ALL_VEHICLES_ACCESS}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().CONSUMPTION_MEASUREMENT} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().CONSUMPTION_MEASUREMENT}</FormLabel>
              <Select
                data-test={dictionary().CONSUMPTION_MEASUREMENT}
                dir='ltr'
                textAlign='right'
                {...register('consumption_measurement', {
                  required: false,
                })}
                sx={{ paddingBottom: '5px ' }}
              >
                <option key={3} value='L_PER_HUNDRED_KM'>
                  {' '}
                  {dictionary().LITERS_PER_HUNDRED_KM}
                </option>
                <option key={1} value='L_PER_KM'>
                  {' '}
                  {dictionary().LITERS_PER_KM}
                </option>
                <option key={2} value='KM_PER_L'>
                  {' '}
                  {dictionary().KM_PER_LITERS}
                </option>
              </Select>
            </FormControl>
            <FormControl id={dictionary().SHIFTS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().SHIFTS}</FormLabel>
              <Controller
                control={control}
                name='use_shifts'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} data-test={dictionary().USE_SHIFTS} textTransform='capitalize' value='true' isChecked={getValues().use_shifts}>
                    {dictionary().USE_SHIFTS}
                  </Checkbox>
                )}
              />
            </FormControl>
            {useShiftValue.current && (
              <FormControl
                id={dictionary().SHIFT_START_TIME}
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors.shift_starting}
              >
                <FormLabel>
                  <>
                    {dictionary().SHIFT_START_TIME}
                    <Required />
                  </>
                </FormLabel>
                <InputGroup>
                  <Input
                    data-test={dictionary().SHIFT_START_TIME}
                    type='text'
                    defaultValue='00:00'
                    {...register('shift_starting', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                      pattern: {
                        value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
                        message: dictionary().STARTING_SHIFT_PATTERN,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.shift_starting?.message}</FormErrorMessage>
              </FormControl>
            )}
            {userType === 'admin' && role === 'SUPER' && (
              <FormControl
                id='deposit_confirmation_type'
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors.deposit_confirmation_type}
              >
                <FormLabel>
                  {dictionary().DEPOSIT_CONFIGURATION} <Required />
                </FormLabel>
                <Controller
                  name='deposit_confirmation_type'
                  control={control}
                  data-test={dictionary().DEPOSIT_CONFIGURATION}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Stack direction='row' gap='15px'>
                        <Radio value='INITIAL_CONFIRMATION' color='purple'>
                          {dictionary().INITIAL_CONFIRMATION}
                        </Radio>
                        <Radio value='FINAL_CONFIRMATION' color='purple'>
                          {dictionary().FINAL_CONFIRMATION}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                  rules={{
                    required: true && dictionary().REQUIRED,
                  }}
                />
                <FormErrorMessage>{errors.deposit_confirmation_type?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl id='pin_code_digits_number' w={['100%', '40%', '40%', '40%']} isInvalid={!!errors.pin_code_digits_number}>
              <FormLabel>
                {dictionary().PIN_CODE_LENGTH} <Required />
              </FormLabel>
              <Controller
                name='pin_code_digits_number'
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction='row' gap={5}>
                      <Radio value={'4'} color='purple'>
                        4
                      </Radio>
                      <Radio value={'6'} color='purple'>
                        6
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
                rules={{
                  required: true && dictionary().REQUIRED,
                }}
              />
              <FormErrorMessage>{errors.pin_code_digits_number?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().SHIFTS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'></FormControl>
          </Flex>
        </form>
      </Card>
      {/* <DepositConfigurationModel
        isOpen={isOpen}
        onCancel={onCancelConfig}
        onClose={onClose}
        message={`مرحلة تأكيد الإيداع لشركة ${getValues()?.name} ليست متوافقة مع ${corporateGroup.name}`}
      /> */}
    </Container>
  )
}

export default EditCorporate
