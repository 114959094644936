import { useRef, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  Modal,
  Spinner,
  Select,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormLabel,
  Button,
  FormControl,
  Input,
  useDisclosure,
  MenuItem,
  Flex,
  InputGroup,
  FormErrorMessage,
  InputLeftElement,
} from '@chakra-ui/react'
import useAlert from '../../helpers/useAlert'
import fetch from '../../helpers/fetch'
import dictionary from '../../dictionary'
import { useStore } from '../../store'
import Reqiured from '../../components/core/reqiured'

interface Props {
  action?: any
  handleIsBalanseChanged?: () => void
}

const TransferModal: React.FC<Props> = ({ action, handleIsBalanseChanged }) => {
  const { userType } = useStore((state) => state)
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { onSuccess, onError } = useAlert()
  // const { isDriverAssigned, setIsDriverAssigned } = UseAutoUpdateVaribles()

  let query: any = {}
  const { pathname } = useLocation()
  if (pathname.includes('vehicles')) {
    query = useQuery(['vehicles', id], () => fetch('GET', `/vehicles/${id}?app=dashboard`), {})
  } else if (pathname.includes('drivers')) {
    query = useQuery(['drivers', id], () => fetch('GET', `/drivers/${id}`), {})
  }
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/corporates/transfer-balance', { data: vars }), {
    onSuccess: (): void => {
      handleIsBalanseChanged && handleIsBalanseChanged()
      onSuccess()
      onClose()
    },
    onError,
  })

  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const AddTransfer = async (formData: any) => {
    formData.account_type = 'FUEL'
    mutate(formData)
  }
  const handleOnOpen = () => {
    onOpen()
    setValue('account_type', null)
    setValue('transfer_direction', null)
    setValue('amount', null)
    error.data = {}
  }
  useEffect(() => {
    if (history?.location?.pathname.includes('drivers')) {
      setValue('ref_type', 'DRIVER')
      setValue('ref_id', query?.data?.id)
      setValue('corporateId', query?.data?.corporate?.id)
    } else if (history?.location?.pathname.includes('vehicles')) {
      setValue('ref_type', 'VEHICLE')
      setValue('ref_id', query?.data?.vehicle?.id)
      setValue('corporateId', query?.data?.corporate?.id)
    }
  }, [query?.data?.vehicle, query?.data?.id])
  return (
    <>
      <MenuItem onClick={handleOnOpen}>{action.title}</MenuItem>

      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ direction: 'rtl' }}>{dictionary().TRANSFER} </ModalHeader>
          <ModalBody pb={6}>
            <form onSubmit={handleSubmit(AddTransfer as any)}>
              <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
                <FormControl id={dictionary().TRASEFER_DIRECRTION} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.account_type}>
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>
                      {dictionary().TRASEFER_DIRECRTION}
                      <Reqiured />
                    </>
                  </FormLabel>
                  <Select
                    placeholder={dictionary().PICK}
                    {...register('transfer_direction', {
                      required: dictionary().REQUIRED,
                    })}
                  >
                    <option value='DEBIT'>{dictionary().CORPORATE_DEBIT}</option>
                    <option value='CREDIT'>{dictionary().CORPORATE_CREDIT}</option>
                  </Select>

                  <FormErrorMessage>{errors.account_type?.message}</FormErrorMessage>
                </FormControl>
                {/* <FormControl id={dictionary().ACCOUNT_TYPE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.account_type}>
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>
                      {dictionary().ACCOUNT_TYPE}
                      <Reqiured />
                    </>
                  </FormLabel>
                  <Select
                    placeholder={dictionary().PICK}
                    {...register('account_type', {
                      required: dictionary().REQUIRED,
                    })}
                  >
                    <option value='FUEL'>{dictionary().FUEL}</option>
                    <option value='TOLL'>{dictionary().TOLL}</option>
                    <option value='STORE'>{dictionary().STORE}</option>
                    <option value='CASH'>{dictionary().CASH_WALLET}</option>
                  </Select>

                  <FormErrorMessage>{errors.account_type?.message}</FormErrorMessage>
                </FormControl> */}
                <FormControl
                  id={dictionary().REQUIRED}
                  w='40%'
                  minH='100px'
                  display='inline-block'
                  isInvalid={
                    !!errors.amount ||
                    error?.data?.message === 'balance is less than the transactions amount' ||
                    error?.data?.message === 'this ref not found or the balance not enough'
                  }
                >
                  <FormLabel style={{ direction: 'rtl' }}>
                    <>
                      {dictionary().AMOUNT} <Reqiured />
                    </>
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement height='100%' pointerEvents='none'>
                      {dictionary().PRICING_CURRENCY}
                    </InputLeftElement>
                    <Input
                      type='number'
                      {...register('amount', {
                        required: dictionary().REQUIRED,
                        valueAsNumber: true,
                      })}
                    />
                  </InputGroup>

                  <FormErrorMessage>{errors.amount?.message}</FormErrorMessage>
                  {
                    <FormErrorMessage>
                      {error?.data?.message === 'balance is less than the transactions amount' && dictionary().BALANCE_IS_LESS_THAN_TRANSACTION_AMOUNT}
                      {error?.data?.message === 'this ref not found or the balance not enough' && dictionary().ACC_TYPE_NOT_FOUND}
                    </FormErrorMessage>
                  }
                </FormControl>

                <FormControl hidden id={dictionary().REFERNCE_ID} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.ref_type}>
                  <FormLabel>{dictionary().REFERNCE_ID} </FormLabel>
                  <InputGroup>
                    <Input
                      readOnly
                      type='text'
                      {...register('ref_id', {
                        required: dictionary().REQUIRED,
                      })}
                    />
                  </InputGroup>

                  <FormErrorMessage>{errors.ref_type?.message}</FormErrorMessage>
                </FormControl>
                <FormControl hidden id={dictionary().REFERNCE_ID_TYPE} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.ref_type}>
                  <FormLabel>{dictionary().REFERNCE_ID_TYPE}</FormLabel>
                  <InputGroup>
                    <Input
                      readOnly
                      type='text'
                      {...register('ref_type', {
                        required: dictionary().REQUIRED,
                      })}
                    />
                  </InputGroup>

                  <FormErrorMessage>{errors.ref_type?.message}</FormErrorMessage>
                </FormControl>
                {userType === '' ? (
                  <FormControl hidden id={dictionary().CORPORATE_ID} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.corporateId}>
                    <FormLabel>{dictionary().CORPORATE_ID}</FormLabel>
                    <InputGroup>
                      <Input
                        readOnly
                        type='text'
                        {...register('corporateId', {
                          required: dictionary().REQUIRED,
                        })}
                      />
                    </InputGroup>

                    <FormErrorMessage>{errors.corporateId?.message}</FormErrorMessage>
                  </FormControl>
                ) : (
                  <></>
                )}
              </Flex>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button onClick={async () => (await trigger()) && AddTransfer(getValues() as any)} colorScheme='blue' mr={3}>
              {isLoading ? <Spinner /> : dictionary().SAVE}
            </Button>
            <Button onClick={onClose}>{dictionary().CANCEL}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TransferModal
