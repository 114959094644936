import React from 'react'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().CHARGING_PROFILE_ID,
    type: 'text',
  },
  {
    id: 'customerId',
    key: 'customer.id',
    name: dictionary().CUSTOMER_ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'customer.name',
    name: dictionary().CUSTOMER_NAME,
    type: 'text',
  },
  {
    id: 'corporateId',
    key: 'corporate.id',
    name: dictionary().CORPORATE_ID,
    type: 'text',
  },
  {
    id: 'name',
    key: 'corporate.name',
    name: dictionary().CORPORATE_NAME,
    type: 'text',
  },
  {
    id: 'vehicle_subscription_fees',
    key: 'vehicle_subscription_fees',
    name: dictionary().SUBSCRIPTION_FEES,
    type: 'financialNumber',
  },
  {
    id: 'nfc_fees',
    key: 'nfc_fees',
    name: dictionary().NFC_FEES,
    type: 'financialNumber',
  },
  {
    id: 'installation_fees',
    key: 'installation_fees',
    name: dictionary().INSTALLATION_FEES,
    type: 'financialNumber',
  },
  {
    id: 'nfc_replacement_fees',
    key: 'nfc_replacement_fees',
    name: dictionary().NFC_REPLACEMENT_COST,
    type: 'financialNumber',
  },
  {
    id: 'internal_stations_fee_rate',
    key: 'internal_stations_fee_rate',
    name: dictionary().INTERNAL_STATION_FEES,
    type: 'financialNumber',
  },
  {
    id: 'fee_rate',
    key: 'fee_rate',
    name: dictionary().EXTERNAL_STATION_FEES,
    type: 'financialNumber',
  },
  {
    id: 'finance_rate',
    key: 'finance_rate',
    name: dictionary().FINANCE_FEE,
    type: 'financialNumber',
  },
  {
    id: 'tips_percentage',
    key: 'tips_percentage',
    name: dictionary().EXTERNAL_STATION_FEES_TIPS,
    type: 'financialNumber',
  },
  {
    id: 'fraud_detection_fees',
    key: 'fraud_detection_fees',
    name: dictionary().FRUAD_FEES,
    type: 'financialNumber',
  },
  {
    id: 'profile_type',
    key: 'type',
    name: dictionary().PROFILE_TYPE,
    type: 'text',
  },
  {
    id: 'version_number',
    key: 'version_number',
    name: dictionary().VERSION_NUMBER,
    type: 'text',
  },
  {
    id: 'version_status',
    key: 'status',
    name: dictionary().VERSION_STATUS,
    type: 'text',
  },
  {
    id: 'trial_duration_in_days',
    key: 'trial_duration_in_days',
    name: dictionary().TRIAL_DURATION,
    type: 'text',
  },
  {
    id: 'starts_at',
    key: 'starts_at',
    name: dictionary().FROM,
    type: 'date',
  },
  {
    id: 'expires_at',
    key: 'expires_at',
    name: dictionary().TO,
    type: 'date',
  },
  {
    id: 'versionActions',
    key: 'versionActions',
    name: '',
    type: 'actions',
  },
]

const ChargingProfiles: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().CHARGING_PROFILES}
      to='/charging-profiles/new'
      // tags={[{ name: dictionary().ALL, filter: [{ name: '', value: '' }] }]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['charging-profiles']}
      countArrayPath={['count']}
      // restRoute={'/corporates'}
      restRoute={'/charging-profile'}
      cashingKey={CASHING_KEY.GET_CHARGING_PROFILES}
    />
  )
}

export default ChargingProfiles
