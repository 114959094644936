import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import SaveButton from '../../components/core/SaveButton'
import Required from '../../components/core/reqiured'
import { INDUSTRY_TYPES } from '../../constants/INDUSTRY_TYPES'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'

type AddCorporateVariables = {
  pin_code_digits_number: number
  customerId: number
  name: any
  // username: any
  // password: any
  credit_limit: any
  is_advanced: boolean
  corporateGroupId: any
  daily_replenish_time?: any
  shift_starting: string
  replenish_type: any
  consumption_measurement: any
  tax_id: string
}

type CustomerData = {
  id: number
  name: string
  is_deleted: boolean
  created_at: string
  settlement: string
  corporates: any[]
}

const AddCorporate: React.FC = () => {
  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({ mode: 'onChange' })
  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess } = useAlert()

  const useShiftValue = useRef({})
  useShiftValue.current = watch('use_shifts', '')
  const watchCustomerId = watch('customerId')
  const replenishType = watch('replenish_type')
  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('POST', '/corporates', { data: vars }), {
    onSuccess: (data: any): void => {
      push('/corporates')
      onSuccess()
    },
  })

  // ------------------- Fetch Selected Customer Data -------------------
  const {
    mutate: mutateCustomer,
    data: customerData,
    reset: resetCustomer,
  } = useMutation<CustomerData>(() => fetch('GET', `/customers/${watchCustomerId}`, {}), {
    onSuccess: (): void => {},
  })

  useEffect(() => {
    if (watchCustomerId?.length > 0) {
      mutateCustomer()
    }
    if (watchCustomerId?.length === 0) {
      resetCustomer()
    }
  }, [watchCustomerId])
  //-----------------------------------------------------------------------

  const calcDailyReplenishTime = (time: string) => {
    let hours: any = time.split(':')[0]
    if (+hours === 0) {
      hours = 10
    } else if (+hours === 1) {
      hours = 11
    } else {
      hours = hours - 2
    }
    hours = (hours as number).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })
    return `${hours}:${time.split(':')[1]}`
  }

  const AddCorporate = async (formData: AddCorporateVariables) => {
    if (customerData?.settlement != 'POST_PAID') {
      formData.credit_limit = 0
    }
    if (formData.daily_replenish_time === '' || replenishType !== 'DAILY') {
      delete formData.daily_replenish_time
    }
    !useShiftValue.current && (formData.shift_starting = '00:00')
    formData.customerId = +formData?.customerId
    formData.pin_code_digits_number = +formData?.pin_code_digits_number
    formData.corporateGroupId = formData?.corporateGroupId?.length > 0 ? formData?.corporateGroupId[0] : null
    formData.name = formData?.name?.trim()
    // formData.username = formData?.username?.trim()
    !formData.replenish_type && delete formData.replenish_type
    formData?.daily_replenish_time && (formData.daily_replenish_time = calcDailyReplenishTime(formData?.daily_replenish_time))

    mutate([formData])
  }

  return (
    <Container>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().ADD_NEW_CORPORATE}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && AddCorporate(getValues() as AddCorporateVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(AddCorporate as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            <FormControl
              id='ADD_NEW_CORPORATE_NAME'
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.name}
            >
              <FormLabel>
                <>
                  {' '}
                  {dictionary().CORPORATE} <Required />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().CORPORATE}
                  type='text'
                  {...register('name', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().CORPORATES_GROUP}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.corporateGroupId}
            >
              <FormLabel>{dictionary().CORPORATES_GROUP}</FormLabel>
              <Controller
                name={`corporateGroupId`}
                rules={{ required: false }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.corporateGroupId}
                    onChange={onChange}
                    value={value}
                    dataTest={dictionary().CORPORATES_GROUP}
                    searchRoute='/corporate-group'
                    singleRoute='/corporate-group'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name}
                  />
                )}
              />
              <FormErrorMessage>{errors?.corporateGroupId?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().CUSTOMER}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              display='inline-block'
              isInvalid={!!errors.customerId}
            >
              <FormLabel>
                {dictionary().CUSTOMER} <Required />
              </FormLabel>
              <Controller
                name={`customerId`}
                rules={{ required: dictionary().REQUIRED }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteInput
                    isInvalid={!!errors?.customerId}
                    onChange={onChange}
                    value={value}
                    dataTest={dictionary().CUSTOMER}
                    searchRoute='/customers'
                    singleRoute='/customers'
                    valueFormatter={(option) => option?.id}
                    displayFormatter={(option) => option?.name}
                  />
                )}
              />
              <FormErrorMessage>{errors?.customerId?.message}</FormErrorMessage>
            </FormControl>

            <FormControl id='TAX_ID' w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block' isInvalid={!!errors.tax_id}>
              <FormLabel>
                <>
                  {' '}
                  {dictionary().TAX_ID} <Required />
                </>
              </FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().TAX_ID}
                  type='text'
                  {...register('tax_id', {
                    required: dictionary().REQUIRED,
                    validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.tax_id?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id={dictionary().INDUSTRY}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.industry}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().INDUSTRY}
                <Required />
              </FormLabel>

              <Select
                data-test={dictionary().INDUSTRY}
                dir='ltr'
                textAlign='right'
                {...register('industry', {
                  required: dictionary().REQUIRED,
                })}
                placeholder={dictionary().INDUSTRY}
              >
                {INDUSTRY_TYPES?.map((item: string, index: number) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors?.industry?.message} </FormErrorMessage>
            </FormControl>

            {customerData?.settlement === 'POST_PAID' && (
              <FormControl
                id={dictionary().CREDIT_LIMIT}
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors.credit_limit}
              >
                <FormLabel>
                  <>
                    {dictionary().CREDIT_LIMIT}
                    <Required />
                  </>
                </FormLabel>
                <InputGroup>
                  <Input
                    data-test={dictionary().CREDIT_LIMIT}
                    disabled={customerData?.settlement !== 'POST_PAID'}
                    type='number'
                    placeholder='0'
                    {...register('credit_limit', {
                      required: dictionary().REQUIRED,
                      valueAsNumber: true,
                      min: {
                        value: 0,
                        message: dictionary().NO_NEGATIVE_NUMBER,
                      },
                    })}
                  />
                  <InputLeftElement>{dictionary().PRICING_CURRENCY}</InputLeftElement>
                </InputGroup>

                <FormErrorMessage>{errors.credit_limit?.message}</FormErrorMessage>
              </FormControl>
            )}

            <FormControl
              id={dictionary().REPLENISH_TIME}
              w={['100%', '40%', '40%', '40%']}
              minH={['50px', '100px']}
              isInvalid={!!errors.replenish_type}
              display='inline-block'
            >
              <FormLabel>
                {dictionary().REPLENISH_TYPE}
                <Text fontSize='sm' color='gray.500' display={'inline-block'} mr={2}>
                  {replenishType === 'WEEKLY' && `(${dictionary().WEEKLY_REPLENISH_TIME})`}
                  {replenishType === 'MONTHLY' && `(${dictionary().MONTHLY_REPLENISH_TIME})`}
                </Text>
              </FormLabel>

              <Select
                data-test={dictionary().REPLENISH_TYPE}
                dir='ltr'
                textAlign='right'
                {...register('replenish_type', {
                  required: false,
                })}
                placeholder={dictionary().REPLENISH_TYPE}
              >
                <option key={3} value='DAILY'>
                  {' '}
                  {dictionary().DAILY}
                </option>
                <option key={1} value='WEEKLY'>
                  {' '}
                  {dictionary().WEEKLY}
                </option>
                <option key={2} value='MONTHLY'>
                  {' '}
                  {dictionary().MONTHLY}
                </option>
              </Select>
              <FormErrorMessage>{errors?.replenish_type?.message} </FormErrorMessage>
            </FormControl>
            {replenishType === 'DAILY' && (
              <FormControl
                id='daily_replenish_time'
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors.daily_replenish_time}
              >
                <FormLabel>{dictionary().DAILY_REPLENISH_TIME}</FormLabel>
                <InputGroup>
                  <Input
                    data-test={dictionary().DAILY_REPLENISH_TIME}
                    type='time'
                    {...register('daily_replenish_time', {
                      required: false,
                      pattern: {
                        value: /^(2[0-3]|[01]?[0-9]):([0-0]?[0-0])$/,
                        message: dictionary().NO_MINUTES,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.daily_replenish_time?.message}</FormErrorMessage>
              </FormControl>
            )}

            <FormControl id={dictionary().IS_DEMO} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().TYPE}</FormLabel>
              <Controller
                control={control}
                name='is_Demo'
                render={({ field: { onChange } }) => (
                  <Checkbox data-test={dictionary().IS_DEMO} onChange={onChange} textTransform='capitalize' value='true'>
                    {dictionary().IS_DEMO}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().ALL_VEHICLES_ACCESS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().DRIVERS}</FormLabel>
              <Controller
                control={control}
                name='all_vehicles_access'
                render={({ field: { onChange } }) => (
                  <Checkbox data-test={dictionary().ALL_VEHICLES_ACCESS} onChange={onChange} defaultValue='false' textTransform='capitalize' value='true'>
                    {dictionary().ALL_VEHICLES_ACCESS}
                  </Checkbox>
                )}
              />
            </FormControl>
            <FormControl id={dictionary().CONSUMPTION_MEASUREMENT} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().CONSUMPTION_MEASUREMENT}</FormLabel>
              <Select
                data-test={dictionary().CONSUMPTION_MEASUREMENT}
                dir='ltr'
                textAlign='right'
                {...register('consumption_measurement', {
                  required: false,
                })}
                defaultValue={'L_PER_HUNDRED_KM'}
                sx={{ paddingBottom: '5px ' }}
              >
                <option key={3} value='L_PER_HUNDRED_KM'>
                  {' '}
                  {dictionary().LITERS_PER_HUNDRED_KM}
                </option>
                <option key={1} value='L_PER_KM'>
                  {' '}
                  {dictionary().LITERS_PER_KM}
                </option>
                <option key={2} value='KM_PER_L'>
                  {' '}
                  {dictionary().KM_PER_LITERS}
                </option>
              </Select>
            </FormControl>
            <FormControl id={dictionary().SHIFTS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'>
              <FormLabel>{dictionary().SHIFTS}</FormLabel>
              <Controller
                control={control}
                name='use_shifts'
                render={({ field: { onChange } }) => (
                  <Checkbox data-test={dictionary().USE_SHIFTS} onChange={onChange} textTransform='capitalize' value='true'>
                    {dictionary().USE_SHIFTS}
                  </Checkbox>
                )}
              />
            </FormControl>
            {useShiftValue.current && (
              <FormControl
                id={dictionary().SHIFT_START_TIME}
                w={['100%', '40%', '40%', '40%']}
                minH={['50px', '100px']}
                display='inline-block'
                isInvalid={!!errors.shift_starting}
              >
                <FormLabel>
                  <>
                    {dictionary().SHIFT_START_TIME}
                    <Required />
                  </>
                </FormLabel>
                <InputGroup>
                  <Input
                    data-test={dictionary().SHIFT_START_TIME}
                    type='text'
                    defaultValue='00:00'
                    {...register('shift_starting', {
                      required: dictionary().REQUIRED,
                      validate: (value) => value.trim() != '' || dictionary().REQUIRED,
                      pattern: {
                        value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
                        message: dictionary().STARTING_SHIFT_PATTERN,
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.shift_starting?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl id='pin_code_digits_number' w={['100%', '40%', '40%', '40%']} isInvalid={!!errors.pin_code_digits_number}>
              <FormLabel>
                {dictionary().PIN_CODE_LENGTH} <Required />
              </FormLabel>
              <Controller
                name='pin_code_digits_number'
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction='row' gap={5}>
                      <Radio value={'4'} color='purple'>
                        4
                      </Radio>
                      <Radio value={'6'} color='purple'>
                        6
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
                rules={{
                  required: true && dictionary().REQUIRED,
                }}
              />
              <FormErrorMessage>{errors.pin_code_digits_number?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().SHIFTS} w={['100%', '40%', '40%', '40%']} minH={['50px', '100px']} display='inline-block'></FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default AddCorporate
