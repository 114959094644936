import { Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import dictionary from '../../../dictionary'
import fetch from '../../../helpers/fetch'
import Card from '../Card'

type version = {
  id: number
  version_number: string
}

type Props = {
  customerId: number
  // ----------------------- Pass corporateId ={-1} to force get versions by customer id ---------------------
  corporateId: number
  // ---------------------------------------------------------------------------------------------------------
  setSelectedVersionId?: (value: number) => void
  selectedVersionId?: string
}

const VersionsList: FC<Props> = ({ customerId, corporateId, setSelectedVersionId, selectedVersionId }) => {
  const { pathname } = useLocation()
  const { push } = useHistory()
  const isAddindView = pathname.includes('/new')

  //--------------- if this charging profile is linked to a single corporate (old) use corporateId -------------
  //-------------------------------- else get versions by customer id ------------------------------------------
  //----------------------------------- -1 means undefined here ------------------------------------------------
  const { isLoading, mutate, data } = useMutation(() =>
    fetch('GET', corporateId !== -1 ? `charging-profile/corporate/${corporateId}/versions` : `charging-profile/customer/${customerId}/versions`),
  )
  //------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    mutate()
  }, [])

  return (
    <Card p={4}>
      <Flex
        fontSize='17px'
        fontWeight='700'
        mb={5}
        pb={5}
        pr={2}
        borderBottom='1px solid rgba(226, 232, 240, 1)'
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <span>{dictionary().VERSIONS}</span>
        {!isAddindView && (
          <Button
            colorScheme='blue'
            sx={{ width: '97px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}
            onClick={() => push(`/charging-profiles/new?customerId=${customerId}`)}
          >
            <img src='/assets/Add.svg' alt='Add' width={'15px'} height={'15px'} style={{ marginTop: '5px' }} />
            <Text
              style={{
                fontFamily: 'Cairo',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '20px',
                textAlign: 'left',
              }}
            >
              {dictionary().ADD}
            </Text>
          </Button>
        )}
      </Flex>
      {isLoading ? (
        <>
          <Skeleton height='50px' mb={2} />
          <Skeleton height='50px' mb={2} />
        </>
      ) : (
        <Box>
          {data?.versions
            ?.sort((a: any, b: any) => a.version_number - b.version_number)
            ?.map((version: version) => {
              return (
                <Flex
                  fontSize='16px'
                  fontWeight='700'
                  key={version.id}
                  h={'58px'}
                  pr={2}
                  mb={2}
                  bg={selectedVersionId && +selectedVersionId === version.id ? '#F2F5F9' : 'transparent'}
                  color={selectedVersionId && +selectedVersionId === version.id ? '#6F65DB' : '#000'}
                  cursor='pointer'
                  alignItems='center'
                  _hover={{
                    backgroundColor: '#F2F5F9',
                    color: '#6F65DB',
                  }}
                  // TODO add onclick
                  onClick={() => setSelectedVersionId?.(version.id)}
                >
                  {`${dictionary().VERSION} ${version.version_number}`}
                  {!isLoading && version?.id == data?.activeVersion && <span style={{ color: '#6F65DB', marginRight: '10px' }}>{`◆`}</span>}
                </Flex>
              )
            })}
        </Box>
      )}
    </Card>
  )
}

export default VersionsList
