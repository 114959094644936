import { Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import AutoCompleteInput from '../../components/core/AutoCompleteInput'
import Card from '../../components/core/Card'
import Container from '../../components/core/Container'
import Header from '../../components/core/Header'
import Reqiured from '../../components/core/reqiured'
import SaveButton from '../../components/core/SaveButton'
import { USER_TYPE } from '../../constants'
import dictionary from '../../dictionary'
import fetch from '../../helpers/fetch'
import useAlert from '../../helpers/useAlert'
import { useStore } from '../../store'

type EditDriverVariables = {
  id: number
  mobileNumber: number
  email: string
  code: any
  name: string
  starting_balance_fuel: number
  is_active: boolean | string
  offline_verification: boolean | string
  is_demo: boolean
  replenish_amount: number | string
  corporateId?: any
}

const EditDriver: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { userType } = useStore((state) => state)
  const { role } = useStore()

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    trigger,
    setValue,
    control,
  } = useForm({
    mode: 'onChange',
  })

  const headerRef = useRef<HTMLDivElement>(null)
  const { push } = useHistory()
  const { onSuccess, onError } = useAlert()

  const { mutate: getDriverData } = useMutation(() => fetch('GET', `/drivers/${id}`), {
    onSuccess: (data) => {
      setValue('corporateId', [data?.corporateId])
      setValue('mobileNumber', data?.mobile_number)
      setValue('code', data?.code)
      setValue('name', data?.name)
      setValue('is_active', data?.is_active)
    },
  })

  const { isLoading, mutate, error } = useMutation((vars: any) => fetch('PATCH', `/drivers`, { data: vars }), {
    onSuccess: (): void => {
      onSuccess()
      push(`/drivers/${id}`)
    },
    onError: (): void => {
      onError('فشل في تحديث السائق. ')
    },
  })

  useEffect(() => {
    getDriverData()
  }, [])

  const editDriver = async (formData: EditDriverVariables) => {
    if (formData?.corporateId) {
      formData.corporateId = formData.corporateId?.[0]
    }
    formData.is_demo = false
    formData.id = Number(id)
    if (!['SUPER', 'MANAGER'].includes(role ?? '')) {
      delete formData?.corporateId
    }
    mutate(formData)
  }

  return (
    <Container h={'100vh'}>
      <Flex ref={headerRef} className='margin-150' flexDir='column'>
        <Header
          title={dictionary().EDIT_DRIVER}
          action={<SaveButton isLoading={isLoading} onClick={async () => (await trigger()) && editDriver(getValues() as EditDriverVariables)} />}
        />
      </Flex>
      <Card p='8'>
        <form onSubmit={handleSubmit(editDriver as any)}>
          <Flex className='margin-100' direction='row' wrap='wrap' gap='5%' align='center' justify='center'>
            {userType === USER_TYPE.ADMIN && (
              <FormControl
                isDisabled={!['SUPER', 'MANAGER'].includes(role ?? '')}
                id={dictionary().CORPORATE}
                w='40%'
                minH='100px'
                display='inline-block'
                isInvalid={!!errors.corporateId}
              >
                <FormLabel>
                  {dictionary().CORPORATE} <Reqiured />
                </FormLabel>
                <Controller
                  name='corporateId'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteInput
                      onChange={onChange}
                      searchRoute='/corporates'
                      singleRoute='/corporates'
                      valueFormatter={(option) => option?.id}
                      displayFormatter={(option) => option?.name}
                      isInvalid={!!errors?.corporateId}
                      value={value}
                      multiple={false}
                      dataTest={dictionary().CORPORATE}
                    />
                  )}
                  rules={{
                    required: true && dictionary().REQUIRED,
                  }}
                />
                <FormErrorMessage>{errors.corporateId?.message}</FormErrorMessage>
              </FormControl>
            )}
            <FormControl id={dictionary().DRIVER_NAME} w='40%' minH='100px' display='inline-block' isInvalid={!!errors.name}>
              <FormLabel>
                {dictionary().DRIVER_NAME} <Reqiured />
              </FormLabel>
              <InputGroup>
                <Input
                  type='text'
                  data-test={dictionary().NAME}
                  {...register('name', {
                    required: dictionary().REQUIRED,
                    pattern: {
                      value: /^[a-zA-Z0-9\s!@#$%^&*()_+=\-.,\u0600-\u06FF]+$/,
                      message: dictionary().NAME_ALPHA_NUMERIC_SPECIAL_CHAR_ONLY,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().PHONE}
              w='40%'
              minH='100px'
              display='inline-block'
              isInvalid={
                !!errors.mobileNumber ||
                (error as any)?.data?.message === 'mobile_number already exists' ||
                (error as any)?.data?.message === 'user name or mobile number is already exist' ||
                (error as any)?.data?.message === `duplicate value ${getValues().mobileNumber} for key mobile_number`
              }
            >
              <FormLabel>{dictionary().PHONE}</FormLabel>
              <InputGroup>
                <Input
                  data-test={dictionary().DRIVER_PHONE}
                  type='text'
                  {...register('mobileNumber', {
                    required: false,
                    pattern: {
                      value: /^01[0125][0-9]{8}$/,
                      message: dictionary().INVALID_PHONE,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.mobileNumber?.message}</FormErrorMessage>
              <FormErrorMessage>
                {(error as any)?.data?.message === 'mobile_number already exists' && dictionary().THIS_PHONE_IS_ALREADY_TAKEN}
                {(error as any)?.data?.message === `user name or mobile number is already exist` && dictionary().THIS_USERNAME_OR_PHONE_IS_ALREADY_TAKEN}
                {(error as any)?.data?.message === `duplicate value ${getValues().mobileNumber} for key mobile_number` &&
                  dictionary().THIS_USERNAME_OR_PHONE_IS_ALREADY_TAKEN}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id={dictionary().DRIVER_CODE}
              w='40%'
              minH='100px'
              display='inline-block'
              isInvalid={!!errors.code || (error as any)?.data?.message === 'Code already exists within same corporate !'}
            >
              <FormLabel>{dictionary().DRIVER_CODE}</FormLabel>
              <Input
                data-test={dictionary().DRIVER_CODE}
                type='number'
                {...register('code', {
                  required: false,
                  pattern: {
                    value: /^[1-9]\d*$/,
                    message: dictionary().THIS_INPUT_ACCEPTED_ONLY_NUMBERS,
                  },
                })}
              />
              <FormErrorMessage>
                {(error as any)?.data?.message === 'Code already exists within same corporate !' && dictionary().CODE_MUST_UNIQUE}
              </FormErrorMessage>
              <FormErrorMessage>{errors?.code?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id={dictionary().STATUS} w='40%' minH='100px' display='inline-block'>
              <FormLabel>{dictionary().STATUS}</FormLabel>
              <Controller
                control={control}
                name='is_active'
                render={({ field: { onChange } }) => (
                  <Checkbox onChange={onChange} textTransform='capitalize' value='true' isChecked={getValues().is_active}>
                    {dictionary().IS_ACTIVE}
                  </Checkbox>
                )}
              />
            </FormControl>
          </Flex>
        </form>
      </Card>
    </Container>
  )
}

export default EditDriver
