import React from 'react'
import ViewAll from '../../components/layout/ViewAll'
import { CASHING_KEY } from '../../constants'
import dictionary from '../../dictionary'

const headers = [
  {
    id: 'id',
    key: 'id',
    name: dictionary().ID,
    type: 'text',
  },
  {
    id: 'corporate_name_ar',
    key: 'corporate.name',
    name: dictionary().CORPORATE,
    type: 'text',
  },
  {
    id: 'plate_number',
    key: 'number_plate',
    name: dictionary().NUMBER_PLATE,
    type: 'text',
  },
  {
    id: 'code',
    key: 'code',
    name: dictionary().CODE,
    type: 'text',
  },
  {
    id: 'model',
    key: 'model',
    name: dictionary().CAR_MODEL,
    type: 'text',
  },
  {
    id: 'brand',
    key: 'brand',
    name: dictionary().CAR_BRAND,
    type: 'text',
  },
  {
    id: 'vehicle_type',
    key: 'vehicle_type.name.ar',
    name: dictionary().VEHICLE_TYPE,
    type: 'text',
  },
  {
    id: 'max_fuel_liters',
    key: 'max_fuel_liters',
    name: dictionary().MAX_FUEL_LITERS,
    type: 'text',
  },
  {
    id: 'fuel_consumption_benchmark',
    key: 'fuel_consumption_benchmark',
    name: dictionary().CONSUMPTION_BENCHMARK,
    type: 'text',
  },
  {
    id: 'vehicles_date_created',
    key: 'created_at',
    name: dictionary().DATE,
    type: 'date',
  },
  {
    id: 'vehicles_time_created',
    key: 'created_at',
    name: dictionary().TIME,
    type: 'time',
  },
  {
    id: 'vehicle_quota',
    key: 'vehicle_quota',
    name: dictionary().AVIALABLE_BALANCE,
    type: 'vehicle_balance',
    isRight: true,
  },
  {
    id: 'editVehicleStatusModal',
    key: 'editVehicleStatus',
    name: dictionary().EDIT_VEHICLE_STATUS,
    type: 'modal',
  },
]

const AdminVehicles: React.FC = () => {
  return (
    <ViewAll
      headers={headers}
      title={dictionary().VEHICLES}
      to='/vehicles/new'
      tags={[
        { name: dictionary().ALL, filter: [{ name: '', value: '' }] },
        { name: dictionary().IS_ACTIVE, filter: [{ name: 'is_active', value: true }] },
        { name: dictionary().NOT_ACTIVE, filter: [{ name: 'is_active', value: false }] },
      ]}
      hasSearch={true}
      sortOptions={[{ name: dictionary().DEFAULT, value: '' }]}
      dataArrayPath={['vehicles']}
      countArrayPath={['count']}
      restRoute={'/vehicles'}
      cashingKey={CASHING_KEY.GET_VEHICLE}
      hasStationDropListFilter={false}
      searchPlaceholder={dictionary().SEARCH_WITH_NAME_OR_ID}
      corporateDropListPlaceholder={dictionary().CHOOSE_CORPORATE_PLACEHOLDER}
    />
  )
}

export default AdminVehicles
